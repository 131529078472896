/**
 * Converts a date and time to a filename string.
 *
 * @param {number} date - The date to format.
 * @param {number} time - The time to format. It can be a number between 0 and 2359.
 * @param {string} [append=''] - Additional string to append at the end of the filename.
 * @returns {string} The filename string with the date and time as 'YYYYMMDDHHMMSS' and the append string.
 */
function getFilenameFromDateTime(date, time, append = '') {
  let res = date.toString();

  if (time < 10) res += '000' + time;
  else if (time < 100) res += '00' + time;
  else if (time < 1000) res += '0' + time;
  else res += time;

  return res + append;
}

export default getFilenameFromDateTime;
