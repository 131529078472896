import moment from 'moment';
import { round } from 'lodash';

const getTotalDue = (ixAcc, data = []) => {
  const found = data.find(row => row.ixAcc === ixAcc);
  return found ? found.dueTotal : 0;
};

const getCurrentDue = (ixAcc, data = []) => {
  const found = data.find(row => row.ixAcc === ixAcc);
  return found ? found.dueCurrent : 0;
};

const getValueFromRow = (ixAcc, rowValue = []) => {
  const found = rowValue.find(value => value.ixAcc === ixAcc);
  return found ? found : {};
};

export const unFlattenPaySched = (data = [], sumAcc) => {
  if (!data?.length) return;

  const bal = {},
    paid = {},
    total = {},
    down = {},
    result = [];

  const firstRow = data.filter(item => item.ixRow === 0);

  sumAcc.forEach(acc => {
    const totalDue = getTotalDue(acc.ixAcc, firstRow);
    const currentDue = getCurrentDue(acc.ixAcc, firstRow);

    total[acc.heading] = totalDue;
    bal[acc.heading] = totalDue;
    paid[acc.heading] = 0;
    down[acc.heading] = currentDue;
  });

  let currentRow = 0;
  let rowValue = data.filter(row => row.ixRow === currentRow);

  while (rowValue.length > 0) {
    const temp = {
      id: currentRow,
      desc: '',
      date: '',
      totalRebate: 0,
      totalPaid: 0,
      totalBal: 0
    };

    sumAcc.forEach(({ heading, ixAcc }) => {
      const value = getValueFromRow(ixAcc, rowValue);

      temp.desc = value.sDesc;
      temp.date = moment(value.dtDate).format('YYYY-MM-DD');

      temp[heading] = value.dueCurrent;
      temp['rebate' + heading] = value.rebate;
      temp['percentage' + heading] = round(value.duePercent * 100);

      paid[heading] += value.dueCurrent;
      bal[heading] -= value.dueCurrent;

      temp['paid' + heading] = paid[heading];
      temp['balance' + heading] = bal[heading];

      temp.totalRebate += value.rebate;
      temp.totalPaid += value.dueCurrent;
      temp.totalBal += bal[heading];
    });

    result.push(temp);
    currentRow++;
    rowValue = data.filter(row => row.ixRow === currentRow);
  }

  return {
    due: total,
    down,
    result
  };
};

export default unFlattenPaySched;
